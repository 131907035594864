import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import { useTranslation } from 'next-i18next'
import Column from '../../base/grid/Column'
import React, { PropsWithChildren } from 'react'
import Row from '../../base/grid/Row'

export type FooterProps = {
    title: string
    rounded?: boolean
    bordered?: boolean
    shadow?: boolean
    dashboard?: boolean
    helpFooter?: boolean
}

const Footer = ({
    children,
    rounded,
    bordered,
    shadow = true,
    title,
    dashboard = false,
    helpFooter = false
}: PropsWithChildren<FooterProps>) => {
    const { t } = useTranslation()

    const className = buildClassesWithDefault(
        {
            'rounded-lg': rounded,
            'shadow-primary-card': shadow,
            border: bordered,
            'border-gray-300': bordered
        },
        `bg-white mt-10  ${helpFooter ? 'mb-28' : 'mb-6'} ${dashboard ? 'ml-2 mr-1.5' : 'ml-8 mr-9'}`
    )

    return (
        <footer className={buildClassesWithDefault(className)} aria-labelledby='footer-heading'>
            <div className='mx-auto px-4 sm:px-6 md:px-5 py-6'>
                <Row>
                    <Column cols={12}>
                        {/* Only screen readers */}
                        <h2 id='footer-heading' className='sr-only'>
                            {t('frontend.footer.screen_reader_title')}
                        </h2>
                        <h3 className='headline text-base md:text-lg max-w-[541px] mb-6'>{title}</h3>
                    </Column>
                </Row>
                {children}
            </div>
        </footer>
    )
}

export default Footer
