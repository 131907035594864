import { HiPhone } from 'react-icons/hi'
import { MdAlternateEmail } from 'react-icons/md'
import { useTranslation } from 'next-i18next'
import Button from '../../base/button/Button'
import Footer, { FooterProps } from '../footer/Footer'
import React from 'react'
import SimpleList from '../../base/simple-list/SimpleList'
import SimpleListItem from '../../base/simple-list/SimpleListItem'

export type ContactFooterProps = Omit<FooterProps, 'title'> & {
    dashboard?: boolean
}

const ContactFooter = ({ dashboard = false, ...props }: ContactFooterProps) => {
    const { t } = useTranslation()

    const renderTitle = (title: string) => {
        return <h3 className='font-normal text-blue-300 text-sm mt-3.5 ml-2 mb-1'>{title}</h3>
    }

    const renderSubtitle = (subtitle: string) => {
        return <h4 className='font-normal text-gray-500 text-[9px] uppercase -mb-0.5 ml-2 pb-3'>{subtitle}</h4>
    }

    const renderContact = (phoneNumber: string, email: string) => {
        return (
            <SimpleList className='ml-2 mb-2'>
                <SimpleListItem>
                    <Button
                        className='hover:underline mb-1 !font-light !leading-4'
                        as='a'
                        size='sm'
                        leftIcon={<MdAlternateEmail />}
                        styleType='text'
                        href={`mailto:${email}`}
                    >
                        {email}
                    </Button>
                </SimpleListItem>
                <SimpleListItem>
                    <Button
                        className='hover:underline !font-light !leading-4'
                        as='a'
                        size='sm'
                        leftIcon={<HiPhone />}
                        styleType='text'
                        href={`tel:${phoneNumber}`}
                    >
                        {phoneNumber}
                    </Button>
                </SimpleListItem>
            </SimpleList>
        )
    }

    return (
        <Footer {...props} title={t('frontend.orders.contact.title')} rounded shadow dashboard={dashboard}>
            <div className='grid xl:grid-cols-3 gap-3.5'>
                <div className='bg-gray-100 rounded-lg px-2.5 py-1'>
                    {renderTitle(t('frontend.orders.contact_footer.interior_shading_technology.title'))}
                    {renderSubtitle(t('frontend.orders.contact_footer.interior_shading_technology.subtitle'))}
                    {renderContact('+420 571 477 554', 'zaluzieinterier@vyroba-vs.cz')}
                    {renderContact('+420 571 477 505', 'janecka@vyroba-vs.cz')}
                </div>
                <div className='bg-gray-100 rounded-lg px-2.5 py-1'>
                    {renderTitle(t('frontend.orders.contact_footer.external.title'))}
                    {renderSubtitle(t('frontend.shared.contact_footer.external.subtitle'))}
                    {renderContact('+420 571 477 512', 'zaluzieexterier@vyroba-vs.cz')}

                    <h4 className='font-normal text-gray-500 text-[9px] uppercase  -mb-0.5 ml-2 pt-3'>
                        {' '}
                        {t('frontend.shared.contact_footer.external.blinds.subtitle')}
                    </h4>

                    {renderContact('+420 571 477 514', 'rolety@vyroba-vs.cz')}
                </div>
                <div className='bg-gray-100 rounded-lg px-2.5 py-1'>
                    {renderTitle(t('frontend.shared.contact_footer.network_products.title'))}
                    {renderSubtitle(t('frontend.shared.contact_footer.network_products.subtitle'))}
                    {renderContact('+420 571 477 509', 'site@vyroba-vs.cz')}
                </div>
                <div className='bg-gray-100 rounded-lg px-2.5 py-1'>
                    {renderTitle(t('frontend.shared.contact_footer.delivery_operator.title'))}
                    {renderContact('+420 571 477 511', 'info1@logistika-vsetin.cz')}
                </div>
                <div className='bg-gray-100 rounded-lg px-2.5 py-1'>
                    {renderTitle(t('frontend.shared.contact_footer.awnings_sectional_garage_doors.title'))}
                    {renderContact('+420 571 477 552', 'markyzy@vyroba-vs.cz')}
                </div>
                <div className='bg-gray-100 rounded-lg px-2.5 py-1'>
                    {renderTitle(t('frontend.shared.contact_footer.complaint.title'))}
                    {renderContact('+420 571 477 555', 'reklamace@vyroba-vs.cz')}
                </div>
            </div>
            {/*            <Row>
                <Column cols={12} lg={6} xl={4} xl2={3}>
                    <div className='bg-gray-200 rounded-lg px-2 py-1'>
                        {renderTitle(t('frontend.orders.contact_footer.interior_shading_technology.title'))}
                        {renderSubtitle(t('frontend.orders.contact_footer.interior_shading_technology.subtitle'))}
                        {renderContact('+420 571 477 554', 'zaluzieinterier@vyroba-vs.cz')}
                        {renderContact('+420 571 477 505', 'janecka@vyroba-vs.cz')}
                    </div>
                </Column>
                <Column cols={12} lg={6} xl={4} xl2={3}>
                    <div className='bg-gray-200 rounded-lg px-2 py-1'>
                        {renderTitle(t('frontend.orders.contact_footer.external.title'))}
                        {renderSubtitle(t('frontend.shared.contact_footer.external.subtitle'))}
                        {renderContact('+420 571 477 512', 'zaluzieexterier@vyroba-vs.cz')}
                        {renderSubtitle(t('frontend.shared.contact_footer.external.blinds.subtitle'))}
                        {renderContact('+420 571 477 514', 'rolety@vyroba-vs.cz')}
                    </div>
                </Column>
                <Column cols={12} lg={6} xl={4} xl2={3}>
                    <div className='bg-gray-200 rounded-lg px-2 py-1'>
                        {renderTitle(t('frontend.shared.contact_footer.network_products.title'))}
                        {renderSubtitle(t('frontend.shared.contact_footer.network_products.subtitle'))}
                        {renderContact('+420 571 477 509', 'site@vyroba-vs.cz')}
                    </div>
                    <div className='bg-gray-200 rounded-lg px-2 py-1 mt-2.5'>
                        {renderTitle(t('frontend.shared.contact_footer.delivery_operator.title'))}
                        {renderContact('+420 571 477 511', 'info1@logistika-vsetin.cz')}
                    </div>
                </Column>
                <Column cols={12} lg={6} xl={4} xl2={3}>
                    <div className='bg-gray-200 rounded-lg px-2 py-1'>
                        {renderTitle(t('frontend.shared.contact_footer.awnings_sectional_garage_doors.title'))}
                        {renderContact('+420 571 477 552', 'markyzy@vyroba-vs.cz')}
                    </div>
                    <div className='bg-gray-200 rounded-lg px-2 py-1 mt-2.5'>
                        {renderTitle(t('frontend.shared.contact_footer.complaint.title'))}
                        {renderContact('+420 571 477 555', 'reklamace@vyroba-vs.cz')}
                    </div>
                </Column>
            </Row>*/}
        </Footer>
    )
}

export default ContactFooter
