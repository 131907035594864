import { getDefaultServerSideProps } from '../../utils/ServerSidePropsHelper'
import { useTranslation } from 'next-i18next'
import AppBarMenu from '../../components/dashboard/app-bar/AppBarMenu'
import CardInfoPanel from '../../components/dashboard/card-info-panel/CardInfoPanel'
import ContactFooter from '../../components/dashboard/contact-footer/ContactFooter'
import DashboardLayout from '../../components/layouts/DashboardLayout'
import Image from 'next/image'
import PostponeDeliveryList from '../../components/dashboard/postpone-delivery/PostponeDeliveryList'
import React from 'react'
import TabInfoPanel from '../../components/dashboard/tab-info-panel/TabInfoPanel'
import type { NextPage } from 'next'

const DashboardPage: NextPage = () => {
    const { t } = useTranslation()

    return (
        <DashboardLayout
            title={t('frontend.dashboard.order_system')}
            description='Dashboard page'
            appBarContent={<AppBarMenu />}
        >
            <Image
                priority={true}
                className='w-full h-full object-cover rounded-lg'
                src='/kasko/img/banner.png'
                alt='Banner'
                width={1066}
                height={130}
            />

            <PostponeDeliveryList />

            <TabInfoPanel />
            <CardInfoPanel />
            <ContactFooter dashboard />
        </DashboardLayout>
    )
}

export const getServerSideProps = getDefaultServerSideProps

export default DashboardPage
